<template>
  <div class="container">
    <div class="topTitle">
      <div class="rightCon">
        <img src="@/assets/images/login/button_back.png" alt="" />
      </div>
    </div>
    <div class="content">
      <div class="logig-info">
        <div class="userAvatar">
          <img
            :src="loginForm.avatar"
            alt=""
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="liItem">
          <div class="left-lable">姓名:</div>
          <div class="right-lable">
            <input
              readonly="true"
              type="text"
              v-model="loginForm.account"
              placeholder=""
            />
          </div>
        </div>
        <div class="liItem">
          <div class="left-lable">工号:</div>
          <div class="right-lable">
            <input
              v-model="loginForm.user_no"
              readonly="true"
              type="text"
              placeholder=""
            />
          </div>
        </div>
        <div class="liItem">
          <div class="left-lable">值班室:</div>
          <div class="right-lable">
            <input readonly="true" type="text" placeholder="001龙岗值班室" />
          </div>
        </div>
        <div class="liItem">
          <div class="left-lable">角色权限:</div>
          <div class="right-lable">
            <input
              v-model="loginForm.role_name"
              readonly="true"
              type="text"
              placeholder=""
            />
          </div>
        </div>
        <div class="liItem">
          <div class="left-lable">当前时间:</div>
          <div class="right-lable">
            <input
              readonly="true"
              type="text"
              v-model="loginForm.create_time"
              placeholder=""
            />
          </div>
        </div>
        <div class="login-btn" @click="login">确认登录</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      pageBaseUrl: "",
      loginForm: JSON.parse(localStorage.getItem("userInfo"))
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
    };
  },
  created() {
    this.init();
    // console.log(this.$store.state.state.userInfo, 'userInfo');
  },
  computed: {},
  methods: {
    init() {
      let that = this;
      let pageUrl = location.href;
      that.pageBaseUrl = pageUrl.split("#")[0];
    },
    login() {
      let that = this;
      // if(this.loginForm.role_name == '管理员'){
      //   that.page()
      // }
      if (that.loginForm.role_name == "管理员") {
        //如果为管理员
          that.page()
      } else if (that.loginForm.role_name == "值班员1") {
          that.page()
      } else if (that.loginForm.role_name == "值班员2") {
        that.page1();
        that.page2();
        // that.$router.push({ path: "/taskList" });
      } else if (that.loginForm.role_name == "值班员3") {
        that.page1();
        that.page2();
        // that.$router.push({ path: "/checkList" });
      }

    },
    page() {
      let that = this;
      let pageUrl = that.pageBaseUrl;
      window.open(pageUrl + "#/index", "_self");
    },
    page1() {
      let that = this;
      let page1Url = that.pageBaseUrl;
      window.open(page1Url + "#/checkList", "_self");
    },
    page2() {
      let that = this;
      let page2Url = that.pageBaseUrl;
      window.open(page2Url + "#/taskList");
    },
  },
};
</script>
<style lang='scss'  scoped>
@import "@/scss/login/login.scss";
</style>
